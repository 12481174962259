<template>
  <v-container>
    <transition name="slide-fade">
      <v-row v-show="topImageSocialShow">
        <v-col cols="12" sm="6" class="pa-0">
          <v-carousel
            cycle
            interval="3500"
            height="500"
            hide-delimiters
            show-arrows-on-hover
          >
            <v-carousel-item
              v-for="(slide, i) in slides"
              :key="i"
            >
              <v-img
                height="500"
                :lazy-src="'https://imagedelivery.net/P8Q_ty7tw49b6DHye1H45g/' + slide.key + '/thum'"
                :src="'https://imagedelivery.net/P8Q_ty7tw49b6DHye1H45g/' + slide.key + '/public'"
              >
              </v-img>
            </v-carousel-item>
          </v-carousel>
        </v-col>
        <v-col cols="12" sm="6" class="pa-0">
          <v-row class="pa-0 ma-0">
            <social-btn
              btnColor="blue"
              iconName="mdi-twitter"
              btnText="Twitter"
              link="https://twitter.com/reitoumikan28"
            >
            </social-btn>
            <social-btn
              btnColor="pink darken-1"
              iconName="mdi-instagram"
              btnText="Instagram"
              link="https://www.instagram.com/reitoumikan28"
            >
            </social-btn>
            <social-btn
              btnColor="red darken-1"
              iconName="mdi-youtube"
              btnText="Youtube"
              link="http://www.youtube.com/user/reitoumikan28"
            >
            </social-btn>
            <social-btn
              btnColor="blue-grey lighten-4"
              imgPath="img/hatenablog-logo.svg"
              btnText="Blog"
              link="http://reitoumikan.hateblo.jp"
            >
            </social-btn>
          </v-row>
        </v-col>
      </v-row>
    </transition>
      <v-row>
        <v-col cols="12" class="my-2">
          <v-lazy
            :options="{
              threshold: .5
            }"
            transition="fade-transition"
          >
            <div
              class="title-text text-center"
            >
              Profile
            </div>
          </v-lazy>
          <v-divider class="mx-5"></v-divider>
        </v-col>

        <v-col cols="12" class="my-2 d-flex justify-center">
          <v-lazy
            :options="{
              threshold: .5
            }"
            transition="fade-transition"
          >
            <v-img
              src="img/icon.png"
              max-width="400"
            >
            </v-img>
          </v-lazy>
        </v-col>
        <v-col cols="12" class="my-2 d-flex justify-center">
          <v-lazy
            :options="{
              threshold: .5
            }"
            transition="fade-transition"
          >
            <div
              class="main-text mx-5 text-center"
            >
              れいミカと申します。<br>
              秋田出身で仙台在住のしがない社会人 兼 アイマスPです。<br>
              こちらでは「自己紹介サイト」と第して、様々な創作物をまとめてみました。少しでもお楽しみ頂けたら幸いです。
            </div>
          </v-lazy>
        </v-col>
        <!-- <v-col cols="12">
          <v-timeline>
            <v-timeline-item
              v-for="n in 3"
              :key="n"
              color="red lighten-2"
              large
            >
              <template v-slot:opposite>
                <span>写真</span>
              </template>
              <v-card class="elevation-2">
                <v-card-title class="text-h5">
                  Lorem ipsum
                </v-card-title>
                <v-card-text>
                  Lorem ipsum dolor sit amet, no nam oblique veritus. Commune scaevola imperdiet nec ut, sed euismod convenire principes at. Est et nobis iisque percipit, an vim zril disputando voluptatibus, vix an salutandi sententiae.
                </v-card-text>
              </v-card>
            </v-timeline-item>
          </v-timeline>
        </v-col> -->
      
      </v-row>

  </v-container>
</template>

<script>
import SocialBtn from '../components/SocialBtn.vue'
  export default {
    components: { SocialBtn },
    name: 'ProfileView',
    title: 'Profile',

    data: () => ({
      topImageSocialShow: false,
      slides: [
        {"key": "a4e24b38-8911-4370-b3b3-cbff60ab6400"}
      ],
    }),

    mounted() {
      this.topImageSocialShow = true;
      fetch("https://royal-rain-a15b.reimika25259469.workers.dev/TOP/")
        .then(response => response.json())
        .then(data => {
          this.slides = data;
        })
        .catch(error => {
          error
        });
    },
  }
</script>

<style scoped>
  .title-text {
    font-family: 'Poppins', sans-serif;
    letter-spacing: -0.05em;
    font-size: 40pt;
  }
  .main-text {
    font-family: 'Noto Serif JP', serif;
    font-size: 20pt;
    letter-spacing: -0.02em;
  }
  .slide-fade-enter-active {
    transition: all 0.8s ease 1s;
  }
  .slide-fade-leave-active {
    transition: all .6s cubic-bezier(3.0, 0.5, 0.8, 3.0);
  }
  .slide-fade-enter, .slide-fade-leave-to
  /* .slide-fade-leave-active below version 2.1.8 */ {
    transform: translateY(30px);
    opacity: 0;
  }

</style>