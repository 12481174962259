<template>
  <v-container>
    <v-row>
      <v-tabs
        color="orange"
        right
        show-arrows
      >
        <v-tab
          v-for="tab in this.tabs"
          :key="tab"
        >
          {{ tab }}
        </v-tab>
        <v-tab-item
          v-for="tab in this.tabs"
          :key="tab"
          class="my-5"
        >
          <photo-gallery
            :galleryName="tab"
          >
          </photo-gallery>
        </v-tab-item>
      </v-tabs>
    </v-row>

  </v-container>
</template>

<script>
import PhotoGallery from '@/components/PhotoGallery.vue';
export default {
  components: {PhotoGallery},
  name: 'PhotoView',
  title: 'Photo',

  data() {
    return {
      loading: true,
      error: null,
      data: null,
      tabs: [
        "FIGURE",
        "DOLL",
        "AR",
        //"NUI",
        "OTHER",
        "G4U",
        "KOIKATSU",
      ],
    };
  },
  computed: {
    imgHeight () {
      // if (this.$vuetify.breakpoint.width > 1200) {
      //   return 1200 * 0.25;
      // }
      // else{
      //   return this.$vuetify.breakpoint.width * 0.25;
      // }
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return 220
        case 'sm': return 230
        case 'md': return 280
        case 'lg': return 300
        case 'xl': return 300
      }
      return null;
    }
  }
};
</script>